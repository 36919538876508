// ** React Imports
import { useState, createContext } from "react";

// ** Create Context
const ActionBtnContext = createContext();

const ActionBtnProviderWrapper = ({ children }) => {
	// ** States
	const [saveConfigurationAction, setSaveConfigurationAction] = useState(false);
	const [saveCalculationAction, setSaveCalculationAction] = useState(false);
	const [saveReportAction, setSaveReportAction] = useState(false);
	const [saveVersionAction, setSaveVersionAction] = useState(false);
	const [commentMode, setCommentMode] = useState(false);
	const [saveRole, setSaveRole] = useState(false);
	const [publishReportVersion, setPublishReportVersion] = useState(false);
	const [showMainSideBar, setShowMainSideBar] = useState(true);
	const [isActivePipeDriveSelectionPage, setActivePipeDriveSelectionPage] = useState(true);
	const [calculationFormulaOrder, setCalculationFormulaOrder] = useState([]);

	const toggleSaveConfigurationAction = (val) => setSaveConfigurationAction(val);

	const toggleSaveCalculationActionAction = (val) => setSaveCalculationAction(val);

	const toggleSaveersionActionAction = (val) => setSaveVersionAction(val);

	const toggleSaveReportAction = (val) => setSaveReportAction(val);

	const toggleCommentModeAction = (val) => setCommentMode(val);

	const togglePublishReportVersion = (val) => setPublishReportVersion(val);

	const toggleAllReset = () => {
		setSaveConfigurationAction(false);
		setSaveCalculationAction(false);
		setSaveVersionAction(false);
	};

	return (
		<ActionBtnContext.Provider
			value={{
				saveConfigurationAction,
				toggleSaveConfigurationAction,
				saveCalculationAction,
				toggleSaveCalculationActionAction,
				saveVersionAction,
				toggleSaveersionActionAction,
				toggleAllReset,
				saveReportAction,
				toggleSaveReportAction,
				commentMode,
				toggleCommentModeAction,
				saveRole,
				setSaveRole,
				togglePublishReportVersion,
				publishReportVersion,
				showMainSideBar,
				setShowMainSideBar,
				isActivePipeDriveSelectionPage,
				setActivePipeDriveSelectionPage,
				calculationFormulaOrder,
				setCalculationFormulaOrder,
			}}
		>
			{children}
		</ActionBtnContext.Provider>
	);
};

export { ActionBtnProviderWrapper, ActionBtnContext };
